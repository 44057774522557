@import url('https://fonts.googleapis.com/css?family=Bebas+Neue:400&display=swap');
@font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 300;
    font-display: auto;
    src: url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-light-300.eot);
    src: url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-light-300.eot?#iefix) format("embedded-opentype"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-light-300.woff2) format("woff2"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-light-300.woff) format("woff"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-light-300.ttf) format("truetype"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-light-300.svg#fontawesome) format("svg");
}
@font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-regular-400.eot);
    src: url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-regular-400.eot?#iefix) format("embedded-opentype"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-regular-400.woff2) format("woff2"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-regular-400.woff) format("woff"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-regular-400.ttf) format("truetype"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-regular-400.svg#fontawesome) format("svg");
}
@font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-solid-900.eot);
    src: url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-solid-900.eot?#iefix) format("embedded-opentype"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-solid-900.woff2) format("woff2"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-solid-900.woff) format("woff"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-solid-900.ttf) format("truetype"), url(https://pro.fontawesome.com/releases/v5.10.0/webfonts/fa-solid-900.svg#fontawesome) format("svg");
}
body[data-v-2a162d2f] {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    font: inherit;
    background: #E9E9E9;
}
@media all {
body[data-v-2a162d2f] {
        font: inherit;
        font-family: Arial, Helvetica, sans-serif;
        background: #E9E9E9;
}
}
body[data-v-2a162d2f] {
    /* CSS Variables that may have been missed get put on body */
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
}
*[data-v-2a162d2f] {
    box-sizing: border-box;
}
body[data-v-2a162d2f] {
    margin: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
body[data-v-2a162d2f] {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
body[data-v-2a162d2f] {
    font-family: Arial, Helvetica, sans-serif;
    background: #E9E9E9;
}
@media all {
body[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
body[data-v-2a162d2f] {
        font-family: Arial, Helvetica, sans-serif;
        background: #E9E9E9;
}
}
body[data-v-2a162d2f] {
    background-color: #e9e9e9;
}
html[data-v-2a162d2f] {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
@media all {
html[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
}
[data-v-2a162d2f]:root {
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
@media (prefers-reduced-motion: no-preference) {
[data-v-2a162d2f]:root {
        scroll-behavior: smooth;
}
}
@media all {
div[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
}
.container-fluid[data-v-2a162d2f] {
    width: 100%;
    padding-right: .75rem;
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: .75rem;
    padding-left: var(--bs-gutter-x, .75rem);
    margin-right: auto;
    margin-left: auto;
}
.p-0[data-v-2a162d2f] {
    padding: 0 !important;
}
*[data-v-2a162d2f],[data-v-2a162d2f]:after,[data-v-2a162d2f]:before {
    box-sizing: border-box;
}
.row[data-v-2a162d2f] {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}
.row>*[data-v-2a162d2f] {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
@media (min-width: 768px) {
.col-md-12[data-v-2a162d2f] {
        flex: 0 0 auto;
        width: 100%;
}
}
.mb-2[data-v-2a162d2f] {
    margin-bottom: .5rem !important;
}
@media (min-width: 768px) {
.col-md-8[data-v-2a162d2f] {
        flex: 0 0 auto;
        width: 66.66666667%;
}
.col-md-4[data-v-2a162d2f] {
        flex: 0 0 auto;
        width: 33.33333333%;
}
}
.markets[data-v-2a162d2f] {
    background: url(https://1exch.net/assets/imgages/teenpatti_bckg.png) no-repeat 0 0;
    background-size: cover;
}
.accordian-view[data-v-2a162d2f] {
    margin-right: 8px;
}
.casino-heading[data-v-2a162d2f] {
    background-color: var(--secondary);
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
}
.casino-table-wrapper .score-panel[data-v-2a162d2f] {
    position: relative;
    min-height: 210px;
}
.mt-1[data-v-2a162d2f] {
    margin-top: .25rem !important;
}
.casino-title[data-v-2a162d2f] {
    background-color: var(--secondary);
    padding: 6px 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card[data-v-2a162d2f] {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}
.accordian-view .card[data-v-2a162d2f] {
    margin-bottom: 15px;
    border: 0 !important;
    box-shadow: 0 6px 16px #0000001a;
    border-radius: 0;
}
.justify-content-center[data-v-2a162d2f] {
    justify-content: center !important;
}
@media all {
span[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
}
.casino-heading .card-header-title[data-v-2a162d2f] {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}
.casino-table-wrapper .showcards-container[data-v-2a162d2f] {
    font-size: 14px;
    width: 100%;
    font-weight: 700;
    position: absolute;
    top: 20px;
    bottom: 10%;
    text-align: center;
}
.casino-table-wrapper .casino-counter[data-v-2a162d2f] {
    position: absolute;
    color: #fff;
    top: 40%;
    width: 100%;
    text-align: end;
    padding-right: 10px;

    font-size: 18px;
    animation: counterPulse 1s infinite;
}
.casino-table-wrapper .casino-counter[data-v-2a162d2f] {
    top: 90%;
}
iframe[data-v-2a162d2f] {
    border: 0;
}
@media all {
iframe[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
}
@media (min-width: 576px) {
.col-sm-12[data-v-2a162d2f] {
        flex: 0 0 auto;
        width: 100%;
}
}
a[data-v-2a162d2f] {
    color: #0d6efd;
    text-decoration: underline;
}
@media all {
a[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
a[data-v-2a162d2f] {
        text-decoration: none;
}
}
.casino-title .game-rules-icon[data-v-2a162d2f] {
    color: #fff;
}
a[data-v-2a162d2f]:hover {
    color: #0a58ca;
}
p[data-v-2a162d2f] {
    margin-top: 0;
    margin-bottom: 1rem;
}
@media all {
p[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
}
.mt-2[data-v-2a162d2f] {
    margin-top: .5rem !important;
}
.card-header[data-v-2a162d2f] {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.card-header[data-v-2a162d2f]:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.accordian-view .card-header[data-v-2a162d2f] {
    padding: 10px 16px;
    align-items: center;
    border: 0 !important;
    display: flex;
    background: var(--secondary);
    border-radius: 0;
}
.personal-info-content th[data-v-2a162d2f] {
    color: black;
}
.card-body[data-v-2a162d2f] {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}
.accordian-view .card-body[data-v-2a162d2f] {
    padding: 0;
}
button[data-v-2a162d2f] {
    border-radius: 0;
}
button[data-v-2a162d2f] {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button[data-v-2a162d2f] {
    text-transform: none;
}
button[data-v-2a162d2f] {
    -webkit-appearance: button;
}
.btn[data-v-2a162d2f] {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.btn-dark[data-v-2a162d2f] {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}
button[data-v-2a162d2f]:not(:disabled) {
    cursor: pointer;
}
.btn[data-v-2a162d2f]:hover {
    color: #212529;
}
.btn-dark[data-v-2a162d2f]:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
}
.casino-table-wrapper .showcards-container .showcards-wrapper[data-v-2a162d2f] {
    position: relative;
    width: 100%;
    height: 100%;
    inset: 0;
}
.table-header[data-v-2a162d2f] {
    display: flex;
    border-bottom: 1px solid #fff;
}
.cards-done[data-v-2a162d2f] {
    background: #2c3e50;
    height: 20px;
    width: 20px;
    border-radius: 40%;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
}
.cards-done.four[data-v-2a162d2f] {
    background: #0088cc;
}
.cards-done.wide[data-v-2a162d2f] {
    background-color: red;
}
.w-100[data-v-2a162d2f] {
    width: 100% !important;
}
@media all {
i[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
}
.fa[data-v-2a162d2f] {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}
.fa[data-v-2a162d2f] {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
}
.fa-arrow-right[data-v-2a162d2f]:before {
    content: "\f061";
}
.margin-minus[data-v-2a162d2f] {
    margin-top: 0;
}
.casino-table-wrapper .showcards-container .showcards-wrapper .top-left-team[data-v-2a162d2f] {
    position: absolute;
    top: 0%;
    left: 5%;
}
.bet-strip[data-v-2a162d2f] {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
}
.dt-full-tab[data-v-2a162d2f],
.dt-full-tab-active[data-v-2a162d2f] {
    padding: 10px;
}
.dt-full-card-wrapper[data-v-2a162d2f] {
    position: relative;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 5px;
    padding: 20px;
}
.dt-full-tab-active[data-v-2a162d2f] {
    color: white !important;
    font-weight: bold;
    border-bottom: #0088cc 2px solid;
}
.bet-strip div[data-v-2a162d2f] {
    width: -webkit-min-content;
    width: min-content;
}
.casino-table-wrapper .casino-counter[data-v-2a162d2f] {
    position: absolute;
    color: #fff;
    top: 40%;


    margin-right: 0px;
}
.casino-table-wrapper .casino-counter[data-v-2a162d2f] {

    top: 77%;
    width: -webkit-max-content;
    width: max-content;
    right: 00px;
    font-size: xx-large;
    display: flex;
    flex-direction: row;
}
.casino-table-wrapper .showcards-container .showcards-wrapper .top-leftTeen[data-v-2a162d2f] {
    position: absolute;
    top: 12%;
    left: 5%;
}
.casino-table-wrapper .showcards-container .showcards-wrapper .top-left-team1[data-v-2a162d2f] {
    position: absolute;
    top: 35%;
    left: 5%;
}
.casino-table-wrapper .showcards-container .showcards-wrapper .top-left11Teen[data-v-2a162d2f] {
    position: absolute;
    top: 46%;
    left: 5%;
}
.box-6[data-v-2a162d2f] {
    width: 60%;
    min-width: 60%;
    max-width: 60%;
}
.country-name[data-v-2a162d2f] {
    font-size: 14px !important;
}
.country-name[data-v-2a162d2f] {
    position: relative;
    align-items: center;
    display: flex;
}
.table-header>div[data-v-2a162d2f] {
    padding: 5px 0;
    color: white;
    border-right: 0;
    border-bottom: 0;
    border-top: 0;
    font-size: 16px;
    line-height: 15px;
}
.table-header>div[data-v-2a162d2f] {
    border-bottom: 0;
}
.table-header>div[data-v-2a162d2f]:first-child {
    padding-left: 5px;
    padding-right: 5px;
}
.text-center[data-v-2a162d2f] {
    text-align: center !important;
}
.box-4[data-v-2a162d2f] {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
}
.back[data-v-2a162d2f] {
    background-color: #72bbef !important;
}
.table-row[data-v-2a162d2f] {
    display: flex;
    border-bottom: 1px solid #fff;
}
.table-row[data-v-2a162d2f] {
    background-color: #e4e4e4;
}
h5[data-v-2a162d2f] {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}
h5[data-v-2a162d2f] {
    font-size: 1.25rem;
}
@media all {
h5[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
}
.m-0[data-v-2a162d2f] {
    margin: 0 !important;
}
.accordian-view h5[data-v-2a162d2f] {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
}
table[data-v-2a162d2f] {
    caption-side: bottom;
    border-collapse: collapse;
}
img[data-v-2a162d2f] {
    vertical-align: middle;
}
@media all {
img[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
img[data-v-2a162d2f] {
        max-width: 100%;
}
}
.img-fluid[data-v-2a162d2f] {
    max-width: 100%;
    height: auto;
}
.me-2[data-v-2a162d2f] {
    margin-right: .5rem !important;
}
b[data-v-2a162d2f] {
    font-weight: bolder;
}
@media all {
b[data-v-2a162d2f] {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
}
}
.table-row>div[data-v-2a162d2f] {
    padding: 5px 0;
    border: 1px solid #fff;
    border-right: 0;
    border-bottom: 0;
    border-top: 0;
    font-size: 16px;
    line-height: 15px;
}
.table-row>div[data-v-2a162d2f]:first-child {
    padding-left: 5px;
    padding-right: 5px;
}
.disabled[data-v-2a162d2f] {
    pointer-events: none;
}
.table-row .suspended-casino[data-v-2a162d2f] {
    position: relative;
}
.casino-row .suspended-casino[data-v-2a162d2f]::after,
.disabled[data-v-2a162d2f]:after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    right: 0;
    background-color: #2c3e5099;
    text-transform: uppercase;
    font-family: "Font Awesome 5 Pro";
    content: "\f023";
    font-weight: 900;
    font-size: 14px;
    top: 0;
    color: #fff;
    cursor: not-allowed !important;
}
.casino-row .suspended-casino[data-v-2a162d2f]::after,
.disabled[data-v-2a162d2f]::after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    right: 0;
    background-color: #2c3e5099;
    text-transform: uppercase;
    font-family: "Font Awesome 5 Pro";
    content: "\f023";
    font-weight: 900;
    font-size: 14px;
    top: 0;
    color: #fff;
    cursor: not-allowed !important;
}
thead[data-v-2a162d2f] {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.accordian-view .card-body thead[data-v-2a162d2f] {
    background-color: #d7d7d7;
}
tbody[data-v-2a162d2f] {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.team-name[data-v-2a162d2f] {
    font-size: 14px !important;
    color: black;
}
.d-block[data-v-2a162d2f] {
    display: block !important;
}
.table-row>div .odd[data-v-2a162d2f] {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1px;
}
.table-row>div .odd~span[data-v-2a162d2f] {
    font-size: 10px;
}
tr[data-v-2a162d2f] {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
th[data-v-2a162d2f] {
    text-align: inherit;
    text-align: -webkit-match-parent;
}
th[data-v-2a162d2f] {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
.accordian-view .card-body thead th[data-v-2a162d2f] {
    font-size: 14px;
    padding: 5px 10px;
    font-weight: 600;
    width: 20%;
    float: left;
}
.accordian-view .card-body thead th[data-v-2a162d2f]:first-child {
    width: 40%;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-EXRQr.style-EXRQr[data-v-2a162d2f] {
    overflow: hidden;
}
#style-Qwzza.style-Qwzza[data-v-2a162d2f] {
    font-weight: 600;
    color: #fff;
    font-size: 15px;
}
#style-4PDyE.style-4PDyE[data-v-2a162d2f] {
    transform: rotateY(0deg);
}
#style-CNwMj.style-CNwMj[data-v-2a162d2f] {
    transform: rotateY(0deg);
}
#style-6oL1w.style-6oL1w[data-v-2a162d2f] {
    transform: rotateY(0deg);
}
#style-vmgQk.style-vmgQk[data-v-2a162d2f] {
    font-weight: 600;
    color: #fff;
    font-size: 15px;
}
#style-ePI1B.style-ePI1B[data-v-2a162d2f] {
    transform: rotateY(0deg);
}
#style-5Y8ri.style-5Y8ri[data-v-2a162d2f] {
    transform: rotateY(0deg);
}
#style-9rqEy.style-9rqEy[data-v-2a162d2f] {
    transform: rotateY(0deg);
}
#style-le5Kd.style-le5Kd[data-v-2a162d2f] {
    border: 1px;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: 0px;
    margin-bottom: -5px;
}
#style-ahq2n.style-ahq2n[data-v-2a162d2f] {
    color: black;
    font-weight: 700;
}
#style-Vi7Mv.style-Vi7Mv[data-v-2a162d2f] {
    color: black;
    font-weight: 700;
}
#style-mVTSZ.style-mVTSZ[data-v-2a162d2f] {
    margin-bottom: 8px;
}
#style-LDzmS.style-LDzmS[data-v-2a162d2f] {
    margin: 2px;
}
#style-ZlADt.style-ZlADt[data-v-2a162d2f] {
    margin: 2px;
}
#style-aOzkb.style-aOzkb[data-v-2a162d2f] {
    margin: 2px;
}
#style-onbJL.style-onbJL[data-v-2a162d2f] {
    margin: 2px;
}
#style-KpTyV.style-KpTyV[data-v-2a162d2f] {
    margin: 2px;
}
#style-V4ZTe.style-V4ZTe[data-v-2a162d2f] {
    margin: 2px;
}
#style-aCdqo.style-aCdqo[data-v-2a162d2f] {
    margin: 2px;
}
#style-Ct8P5.style-Ct8P5[data-v-2a162d2f] {
    margin: 2px;
}
#style-kRbOr.style-kRbOr[data-v-2a162d2f] {
    margin: 2px;
}
#style-nxtyH.style-nxtyH[data-v-2a162d2f] {
    margin: 2px;
}
#style-DAmMM.style-DAmMM[data-v-2a162d2f] {
    margin-bottom: 10px;
}