.odd-remark {
    width: 100%;
    color: tomato
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');



.menu a {
    color: black !important;
}

.menu i {
    color: black !important;
}

.sidebar ul.menu a {
    font-weight: 400;
    text-transform: capitalize !important;
    color: black !important
}

ul.menu a i {
    color: black !important
        /* color: rgb(122, 129, 141); */
}

.menu a {
    font-weight: 400 !important;
}

ul.menu a {
    font-weight: 400;
    text-transform: capitalize !important;
    color: white;

}

ul.menu div {
    font-weight: 400;
    text-transform: capitalize !important;
    color: black;

}

.offcanvas-body {
    padding: 0px;
}

ul.menu li {
    border-top: 1px solid black;
}


.iADzKJ {
    /* background-image: url("https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/homeExchangeBg.png"); */
    background-position: 80% center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    padding: 0px;
    min-height: 100%;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    position: relative;
    overflow-y: scroll;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}



:selection {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.jrMOYv {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 0px 0px 10px;
}

.gPOtIJ {

    opacity: 0.95;
    border-radius: 10px;
    width: 85%;
    height: auto;
    max-height: 65%;

    position: relative;
    top: -20%;
}

.gbjswO:after {
    content: "";
    height: 30px;
    background: url("https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/SwipeIcon.svg") no-repeat;
    position: absolute;
    right: 21px;
    top: -5px;
    z-index: 9;
    width: 23px;
}

.hxBqlt {
    right: 0px;
    z-index: 999;
    cursor: pointer;
    left: 10px;
    top: 10px;
    position: static;
    padding-top: 0.5rem;
}

.iCbUJJ {
    top: 0px;
    right: 0px;
    z-index: 999;
    cursor: pointer;
}

.bBwrS {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 0px;
    width: auto;
    height: auto;
    background: var(--secondary);
    z-index: 88888;
}

.fnNlPO {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: linear-gradient(rgb(18, 18, 57) 0%, rgb(21, 20, 57) 62%, rgb(21, 20, 57) 100%);
    border-radius: 0px 0px 10px 10px;
    padding: 40px 0px 0px !important;
}

.klPshi {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgb(0, 6, 51);
    max-height: 104px;
    gap: 2px;
    padding: 4px;
}

.klPshi:-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}

.hxBqlt svg {
    width: 130px;
    height: 60px;
}

h4 {
    font-family: Roboto;
}

.dKuVpg {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    margin: -12px 0px 5px;
}

.fnNlPO form {
    padding: 0px 20px;
}

.kiHRcy {
    width: 100%;
    margin-top: 11px;
}

.jfklLT {
    background-image: url("https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/loginBGfooter.png");
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

img {
    -webkit-user-drag: none;
    -webkit-user-select: none;
            user-select: none;
}

.klPshi img {
    width: 60px;
}

.klPshi img {
    width: 100%;
    height: 100%;
    padding: 2px;
}

.klPshi .smallImage {
    width: 70%;
    height: 70%;
}

.lisheX {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    margin: 7px 0px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    box-shadow: rgba(37, 40, 63, 0.5) 0px 0px 50px;
    border-radius: 5px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 14px;
    color: rgb(62, 57, 153);
    position: relative;
}

h1 {
    font-family: Roboto;
}

.fnNlPO h1 {
    font-size: 12px;
    color: rgb(255, 96, 96);
    margin: 3px;
    font-weight: 500;
}

.jnVwYT {
    position: relative;
}

.fvDnWc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px 0px;
}

.hfHJim {
    justify-content: flex-start;
}

.fltqBV {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.kiHRcy p {
    font-family: Roboto;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    text-align: center;
    margin: 5px 0px;
}

.kiHRcy p {
    text-decoration: none;
}

.fPgYVa {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3em;
}

.egDadO {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 10px 10px;
    transition: 0.2s ease-in-out;
    position: relative;
}

.dyrUWh {
    position: absolute;
    font-size: 0.8rem;
    z-index: 1;
    text-transform: uppercase;
}

.lisheX input {
    border: none;
    width: 94%;
    z-index: 1;
    background: transparent;
    outline: none;
}

.iPXXpm {
    display: block;
    width: 5px;
    position: absolute;
    right: 25px;
    top: 14px;
}

.chXqJk {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 8px;
}

.hfHJim p {
    font-size: 12px;
    color: red;
    margin: 0px;
    text-align: center;
}

.QeAZN {
    background: var(--secondary);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 10px;
    border: none;
}

.kiHRcy p span {
    font-family: Roboto;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-left: 5px;
    color: rgb(231, 196, 15);
    text-decoration: underline;
}

.jfklLT img {
    width: 100% !important;
}

.egDadO img {
    width: 100%;
    padding: 2px;
}

.jfklLT .award-icon {
    width: 100% !important;
}

.dyrUWh span {
    color: red;
    padding-left: 2px;
}

.lisheX input[type="password"] {
    position: relative !important;
}

.dRKoPa {
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 11px;
    margin-left: 5px;
}

.fdyjly {
    font-size: 14px;
    width: 100%;
    border: 0px;
    padding: 0.75em 1.25em;
    caret-color: rgb(8, 32, 121);
    background-color: rgb(249, 249, 249);
    color: rgb(36, 38, 41);
    border-radius: 2px;
    flex-grow: 1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 4px inset;
}

.chXqJk .sc-ccbnFN {
    border: 1px solid rgb(70, 67, 194);
    margin: 0px 5px 0px 0px;
    width: 18px !important;
    height: 18px !important;
}

.chXqJk input[type="checkbox"] {
    accent-color: rgb(85, 85, 246);
}

.dRKoPa .agreeAge {
    display: inline-block;
    width: calc(100% - 23px);
}

.dRKoPa .agreeAgeColor {
    color: rgb(246, 167, 45);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-UzD4O.style-UzD4O {
    justify-content: space-between;
}

.App {
    /*   background-image: url("../public/assets/images/pppp.png"); */
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    background-size: cover;
    color: #333;
    margin: 0;
    padding: 0;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100vh;
    overflow-y: scroll;
}

.sc-krDsej {
    font-weight: bold;
}

.font-17 span {
    color: black;
}

.sesstion-bets td {
    color: black;
}


@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap');

body {
    background: rgb(234, 244, 253);
    background-image: url("https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/homeExchangeBg.png");
    font-family: Inter;
}

* {
    box-sizing: border-box;
}

body {
    background-color: #000;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.kBPyVG {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cKHfpx {
    display: flex;
}

.kfJPwG {
    position: relative;
    font-family: Montserrat, sans-serif;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.66px;
    justify-content: space-between;
    background: rgb(221, 225, 231);
    color: rgb(255, 255, 255);
    border-bottom: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}

.bJSTZQ {
    width: 100%;
    display: flex;
    background: rgb(255, 255, 255);
    border-radius: 0px 0px 20px;
    font-size: 13px;
    justify-content: space-evenly;
    padding-right: 0px;

    position: relative;
}

.bJSTZQ {
    border-bottom: 2px solid black;
    margin-bottom: 0px !important;
}



.iILsgX {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 99;
    cursor: pointer;
}

.foOcVP {
    height: auto;
    background: rgb(0, 0, 0);
    width: 44%;
    line-height: 35px;
    position: relative;
    color: black !important;
}

.foOcVP:after {
    width: 50px;
    background: rgb(0, 0, 0);
    position: absolute;
    height: 100%;
    content: " ";
    right: -22px;
    top: 0px;
    transform: skew(45deg);
}

.eTlYgY {
    display: flex;
    padding: 0.5em;
}

.bbKLbo {
    flex-basis: 65%;
    justify-content: center;
    position: relative;
    min-width: 0px;
    padding: 0px;
}

.fNrpGt {
    display: flex;
}

.grQHZb {
    flex-basis: 80%;
    border-right: 1px solid rgba(128, 128, 128, 0.2);
    padding-left: 2em;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.fbPdEN {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25em;

    flex-shrink: 0;
}

.kjYtWL {
    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 12px;
    padding-left: 20px;
}

.ddDXjD {
    width: 16.01%;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.07em;
    text-transform: capitalize;
    font-size: 13px;
    margin-right: 1px;
    color: rgb(3, 117, 204);
    margin-left: 69px;
}

.efEdVc {
    position: absolute;
    inset: 0px;
    z-index: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    background-color: rgba(62, 62, 62, 0.636);
}

#tvBtn {
    color: #000 !important;
    font-weight: bolder;

}

#tvBtn i {
    color: #000 !important;
}

.jbTwHB {
    width: 16.01%;
    height: 40px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.07em;
    text-transform: capitalize;
    font-size: 13px;
    margin-left: 1px;
    color: rgb(224, 60, 60);
}

.jwPIDK {
    display: flex;
    width: 100%;
}

.hoLQnR {
    display: flex;
    width: 100%;
}

button {
    outline: 0;
}

.bCKsOU {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    padding: 0px;
    flex-shrink: 0;
    font-size: 14px;
    margin: 0.2em 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.15s ease-in;
}

.bCKsOU:hover {
    opacity: 0.85;
    transition: opacity 0.25s ease-out;
}

.iAODgt {
    margin-left: 10px;
    font-size: x-small;
}

.GameList {
    background-color: rgb(221, 225, 231);
    color: #000 !important;
}

.hEgtIY {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
}

.hOlWHp {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    margin-top: 0.25em;
}

.bzYjxs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    background-color: rgb(163, 163, 163);
}

@media only screen and (max-width: 1200px) {
    .bzYjxs {
        width: 45px;
    }
}

.QtCGw {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    border-right: 2px solid black;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    background-color: rgb(130, 207, 255);
}

@media only screen and (max-width: 1200px) {
    .QtCGw {
        width: 45px;
    }
}

.QtCGw:hover {
    box-shadow: rgb(25, 161, 239) 0px 0px 0px 3px inset;
    transition: 0.5s;
}

.fvpuXi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;

    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    border-right: 2px solid black;
    cursor: pointer;
    background-color: rgb(255, 181, 189);
}

@media only screen and (max-width: 1200px) {
    .fvpuXi {
        width: 45px;
    }
}

.fvpuXi:hover {
    box-shadow: rgb(181, 88, 108) 0px 0px 0px 3px inset;
    transition: 0.5s;
}

.beotnh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
    background-color: rgb(163, 163, 163);
}

@media only screen and (max-width: 1200px) {
    .beotnh {
        width: 45px;
    }
}

.beotnh:hover {
    box-shadow: rgb(25, 161, 239) 0px 0px 0px 3px inset;
    transition: 0.5s;
}

.jOSKLT {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 55px;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
    background-color: rgb(163, 163, 163);
}

@media only screen and (max-width: 1200px) {
    .jOSKLT {
        width: 45px;
    }
}

.jOSKLT:hover {
    box-shadow: rgb(181, 88, 108) 0px 0px 0px 3px inset;
    transition: 0.5s;
}

.iILsgX img {
    width: 20px;
    height: 20px;
}

.hEgtIY h1 {
    text-transform: lowercase;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
}

.hEgtIY h1::first-letter {
    text-transform: capitalize;
}

.jtgJVn {
    font-size: 15px;
    color: rgb(31, 31, 31);
    text-align: center;
    margin-bottom: 0.1em;
    font-weight: bold;
}

@media only screen and (max-width: 1200px) {
    .jtgJVn {
        font-size: 13px;
    }
}

.gNLGlZ {
    font-size: 10px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    color: rgb(36, 38, 41);
}

.kjYtWL span {
    color: rgb(255, 255, 255);
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-Zo2Ej.style-Zo2Ej {
    color: transparent;
    display: inline-block;
    height: 100%;
    width: 100%;
}

#style-EgvzT.style-EgvzT {
    color: transparent;
    display: inline-block;
    height: 100%;
    width: 100%;
}

h1 {
    color: black;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

body {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-body-font-family);
    font-size: 1rem;
    font-size: var(--bs-body-font-size);
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    color: #212529;
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    font-style: normal;
    background: #e4eff1;
    background: var(--theme-bg);
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-family: var(--bs-body-font-family);
    font-size: 1rem;
    font-size: var(--bs-body-font-size);
    font-weight: 400;
    font-weight: var(--bs-body-font-weight);
    line-height: 1.5;
    line-height: var(--bs-body-line-height);
    color: #212529;
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: #fff;
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 17px;
    overflow-x: hidden;
    color: #fff;
    color: var(--text-white);
    background: #e4eff1;
    background: var(--theme-bg);
    padding-bottom: 85px;
}

:root {
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

:root {
    --bg-primary: #0092ad;
    --bg-primary2: #01667a;
    --text-white: #fff;
    --theme-bg: #e4eff1 !important;
}

*,
:after,
:before {
    box-sizing: border-box;
}

.continue_btn {
    margin-top: 20px;
    text-align: center;
}

.details_background {
    background: #fff;
    margin-top: 20px;
    display: inline-block;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

button {
    border-radius: 0;
}

button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button {
    text-transform: none;
}

[type="button"],
button {
    -webkit-appearance: button;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn-primary {
    background-color: #0092ad;
    background-color: var(--bg-primary);
    border-color: #0092ad;
    border-color: var(--bg-primary);
}

[type="button"]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

.btn:hover {
    color: #212529;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}

.btn-primary:hover {
    color: #fff;
    background-color: #01667a;
    background-color: var(--bg-primary2);
    border-color: #01667a;
    border-color: var(--bg-primary2);
}

h4 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h4 {
    font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
    h4 {
        font-size: 1.5rem;
    }
}

.bZXZnm {
    width: 100%;
    background: rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.details_background h4 {
    padding: 10px 20px;
    background: #0092ad;
    background: var(--bg-primary);
    font-size: 16px;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 0px;
    font-weight: 400;
}

.tabs_block_cms {
    padding: 20px;
}

ul {
    padding-left: 2rem;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.fade {
    transition: opacity .15s linear;
}

.fade:not(.show) {
    opacity: 0;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: .25rem;
}

.nav-pills .nav-link {
    color: #000;
}

.nav-pills .nav-link.active {
    color: #fff;
    background-color: #0d6efd;
}

.tabs_block_cms .nav-pills .nav-link.active {
    color: #fff;
    background-color: #000;
}

.nav-link:hover {
    color: #0a58ca;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.tab_inner_details p {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900,100i,200i,300i,400i,500i,600i,700i,800i,900i&display=swap');

body {
    background-image: url("https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/yoloHomepageBg.jpg");
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-family: Roboto;
}

* {
    box-sizing: inherit;
}

body {
    width: 100%;
    height: 100%;
    overflow: visible;
}

:root body {
    margin: 0px;
    padding: 0px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-family: Roboto;
}

:root {
    box-sizing: border-box;
}

:root {
    min-width: 320px;
}

:root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}

.bZXZnm {
    width: 100%;
    background: rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

*,
:before,
:after {
    box-sizing: inherit;
}

.bZXZnm:after {
    content: "";
    width: 82px;
    height: 10px;
    background: rgb(0, 0, 0);
    position: absolute;
    right: -2px;
    top: -9px;
    transform: skew(328deg);
}

:selection {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.ifCcgI {
    font-family: Montserrat, sans-serif;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 0.6em;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.1em;
    color: rgb(6, 6, 6);
}

.fLBGGr {
    color: rgb(255, 255, 255);
    font-weight: normal;
    font-size: 12px;
    background: linear-gradient(92.92deg, rgb(111, 109, 230) 0%, rgb(49, 45, 185) 93.54%);
    padding: 5.2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: skew(338deg);
}

.bgIeHM {
    color: rgb(222, 197, 157);
    font-weight: normal;
    font-size: 12px;
    padding: 3px 10px 3px 4em;
}

.ihIKhX {
    display: flex;
    align-items: baseline;
    color: rgb(16, 43, 141);
}

.bZXZnm .sc-hMFtBS {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    padding: 3px 30px 3px 15px;
}

.fLBGGr span {
    transform: skew(-338deg);
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

body {
    background-image: url("https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/yoloHomepageBg.jpg");
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-family: Roboto;
}

* {
    box-sizing: inherit;
}

body {
    width: 100%;
    height: 100%;
    overflow: visible;
}

:root body {
    margin: 0px;
    padding: 0px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-family: Roboto;
}

:root {
    box-sizing: border-box;
}

:root {
    min-width: 320px;
}

:root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}

.gQQySU {
    display: flex;
    margin: 0px 0px 10px;
    position: relative;
    left: -11px;
    overflow: hidden;
    padding-top: 5px;
}

*,
:before,
:after {
    box-sizing: inherit;
}

:selection {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.kitwBU {
    cursor: pointer;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: bold;
    padding: 6px 20px;
    margin-right: 5px;
    transform: skew(338deg);
    position: relative;
    border-radius: 5px;
    background: linear-gradient(92.92deg, rgb(111, 109, 230) 0%, rgb(49, 45, 185) 93.54%);
    color: rgb(255, 255, 255);
}

.ipverp {
    cursor: pointer;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    padding: 6px 20px;
    background: linear-gradient(270deg, rgb(255, 255, 255) -4.14%, rgba(241, 241, 241, 0.7) 84.48%);
    margin-right: 5px;
    transform: skew(338deg);
    position: relative;
    border-radius: 5px;
}

.kitwBU p {
    margin: 0px;
    padding: 0px;
    transform: skew(-338deg);
}

.ipverp p {
    margin: 0px;
    padding: 0px;
    transform: skew(-338deg);
    color: #000;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

body {
    background-image: url("https://opt-v3-files.raksahb.com/static/mobile/brand/31/image/yoloHomepageBg.jpg");
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-family: Roboto;
}

* {
    box-sizing: inherit;
}

body {
    width: 100%;
    height: 100%;
    overflow: visible;
}

:root body {
    margin: 0px;
    padding: 0px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    font-family: Roboto;
}

:root {
    box-sizing: border-box;
}

:root {
    min-width: 320px;
}

:root {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}

*,
:before,
:after {
    box-sizing: inherit;
}

:selection {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}

.khGOWT {
    display: flex;
    font-family: "Open Sans", sans-serif;
    color: rgb(16, 43, 141);
    font-size: 12px;
    background-color: rgb(255, 255, 255);
    min-height: 44px;
    flex-wrap: wrap;
    position: relative;
}

.khGOWT:not(:last-of-type) {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

.sc-kGXeez {
    color: rgb(0, 33, 80);
    background: rgb(255, 255, 255);
    z-index: 0;
}

.sc-kGXeez:not(:last-of-type) {
    background: rgb(255, 255, 255);
}

.dKVDXy:first-child {
    border-radius: 4px;
}

.egXicQ {
    display: flex;
    width: 16%;
    border-right: 1px solid rgba(128, 128, 128, 0.2);
    padding: 0.5em;
    justify-content: center;
}

.khGOWT>div {
    position: relative;
    z-index: 2;
}

.kuBKyh {
    display: flex;
    width: 52%;
    padding: 0.4em 0px;
    margin-left: auto;
    z-index: 0;
    position: relative;
}

.iVLMqL {
    display: flex;
    width: 32%;
}

.jILwiI {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer;
    min-width: 0px;
    font-size: 12px;
    padding: 0.5em 5px 0.5em 10px;
    position: relative;
    background-color: rgb(212, 211, 249);
    -webkit-clip-path: polygon(0px 0px, 100% 0%, 100% 100%, 3% 100%);
            clip-path: polygon(0px 0px, 100% 0%, 100% 100%, 3% 100%);
}

.buTKXN {
    display: flex;
    font-size: 11px;
    width: 100%;
    max-width: 96px;
    border-radius: 2px;
    margin: 0px 0.5em;
    overflow: hidden;
}

.hcRiFD {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.domUad {
    position: absolute;
    inset: 0px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
    color: rgb(37, 67, 138);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
}

.dPlIVo {
    display: flex;
    padding: 0px 0.25em;
    width: calc(33.3333%);
}

.bDjVzo {
    background-color: transparent;
    border: 0px;
    padding: 0px;
    flex-shrink: 0;
    font-size: 14px;
    margin: 0px 0.5em;
    display: flex;
    align-items: center;
}

.dlYXPt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25em;
    height: 1.25em;
    flex-shrink: 0;
}

.dPeoaT {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.iVLMqL {
    width: 100%;
}

.bfFvRZ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    background-color: rgb(130, 207, 255);
}

.chfdGi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    background-color: rgb(255, 181, 189);
}

.iamGEo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.hqQHXW {
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    padding: 0px;
    flex-shrink: 0;
    font-size: 14px;
    margin: 0px 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    transition: opacity 0.15s ease-in;
}

.hqQHXW:hover {
    opacity: 0.85;
    transition: opacity 0.25s ease-out;
}

.geCOmX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
    background-color: rgb(163, 163, 163);
}

.geCOmX:hover {
    box-shadow: rgb(2, 158, 255) 0px 0px 0px 2px;
}

.ebhzAF {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    flex-grow: 1;
    padding: 0.12em 0.5em;
    margin: 0px 0.15em;
    position: relative;
    z-index: 0;
    text-align: center;
    min-height: 42px;
    cursor: pointer;
    pointer-events: none;
    opacity: 0.5;
    background-color: rgb(163, 163, 163);
}

.ebhzAF:hover {
    box-shadow: rgb(255, 54, 75) 0px 0px 0px 2px;
}

img {
    -webkit-user-drag: none;
    -webkit-user-select: none;
            user-select: none;
}

.dlYXPt>img {
    width: 100%;
    height: auto;
}

.becvOz {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.12em 0.2em;
    width: calc(33.3333%);
}

.bpYEWE {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.25em;
    color: rgb(31, 31, 31);
}

.enjlwj {
    font-size: 8px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    color: rgb(36, 38, 41);
}

.dTEysJ {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(16, 43, 141);
    text-transform: capitalize;
    font-weight: bold;
}

.cwTOUD {
    color: rgb(103, 120, 227);
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    cursor: pointer;
}

.hqQHXW img {
    max-width: 100%;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-mkkhr.style-mkkhr {
    position: relative;
}

#style-3jBpb.style-3jBpb {
    position: relative;
}

#style-9Q69V.style-9Q69V {
    position: relative;
}